// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDtWb47Z8HzkpU_rUHR-Wv5SjF5YUXmyV4",
  authDomain: "alportsegment.firebaseapp.com",
  projectId: "alportsegment",
  storageBucket: "alportsegment.appspot.com",
  messagingSenderId: "201527366036",
  appId: "1:201527366036:web:621f6e4a509f096f673f81"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore()
const auth = getAuth()
const storage = getStorage(app);

export { db , auth , storage}